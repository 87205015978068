<!-- 邀请注册 -->
<template>
    <div class="frame">
        <div style="margin: 100px auto;" class="reg-frm">
            <div>
                <div style="margin-top: 0px;">
                    <div style="text-align: center;font-size:22px;margin-bottom:10px;" class="form-title">邀请更多同事上线使用</div>
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
                        <el-form-item v-for="(Invite, index) in ruleForm.Invites" :key="Invite.key"
                            :prop="'Invites.' + index + '.value'" :rules="rules.phone">
                            <el-col :span="24" class="flex" style="position:relative;">
                                <el-input v-model="Invite.value" :maxlength="11" placeholder="请输入手机号码"></el-input>
                                <div style="position:absolute;top:0;right:-60px;">
                                    <el-button v-if="index>0" @click.prevent="removeInvite(Invite)" icon="el-icon-delete"></el-button>
                                </div>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="addInvite" style="width:100%;">
                                <a-icon type="plus" /><span>添加更多</span>
                            </el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="confirm('ruleForm')" style="width: 100%;">确认邀请</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="success" @click="gotoWhere()" style="width: 100%;">跳过</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="24" class="Invite-frm">
                                <div>邀请码：{{InviteData.invite_code}}</div>
                                <div>机构号：{{InviteData.comp_code}}</div>
                                <div>过期时间：{{InviteData.expired_at | moment("YYYY-MM-DD HH:mm:ss") }}</div>
                            </el-col>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { genInviteCode,sendInviteCode } from '@/service/account'
    export default {
        props: {

        },
        data() {
            var checkPhone = (rule, value, callback) => {
                if (!value) {
                return callback(new Error('请输入手机号'));
                } else {
                    const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('请输入正确的手机号'));
                    }
                }
            };
            return {
                ruleForm: {
                    Invites: [{
                        value: ''
                    }]
                },
                rules: {
                    phone: [{validator: checkPhone, trigger: 'blur'}],
                },
                InviteData:{
                    comp_code:null,
                    invite_code:null,
                    expired_at:null
                },
                from:null
            }
        },
        mounted() {
            genInviteCode().then(resp=>{
                this.InviteData=resp;
            });
            this.from=this.$route.query.from;
        },
        methods: {
            goto(path){
                this.$router.push(path);
            },
            confirm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let phones = this.ruleForm.Invites.reduce((pre,cur)=>{
                            pre.push(cur.value);
                            return pre;
                        },[]).join();
                        console.log(phones);
                        sendInviteCode(this.InviteData.invite_code,phones).then(()=>{
                            this.$message.success('邀请成功，已通过短信方式通知受邀人');
                            this.gotoWhere();
                        }).catch(err=>{
                            console.log(err);
                            this.$message.error(err.message);
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            gotoWhere() {
                if (this.from == "contact") {
                    this.$router.push('/contact');
                } else {
                    this.$router.push('/comp_base');
                }
            },
            removeInvite(item) {
                var index = this.ruleForm.Invites.indexOf(item)
                if (index !== -1) {
                    this.ruleForm.Invites.splice(index, 1)
                }
            },
            addInvite() {
                this.ruleForm.Invites.push({
                    value: '',
                    key: Date.now()
                });
            }
        }
    }

</script>
<style scoped>
    .frame {
        color: rgba(41, 41, 41, 1);
        background: rgba(255, 255, 255, 1);
        height: 100%;
        overflow: auto;
    }

    .frame-title {
        font-size: 24px;
        font-weight: bold;
        width: 304px;
        margin: 45px auto;
        margin-bottom:0px;
        overflow: hidden;
    }

    .form-title {
        font-size: 16px;
        font-weight: bold;
        color: rgba(77, 77, 77, 1);
    }

    .reg-frm {
        margin: 0 auto;
        width: 600px;
        background: rgba(255, 254, 255, 1);
        box-shadow: 0px 0px 20px 0px rgba(198, 198, 198, 0.5);
        border-radius: 20px;
        overflow: hidden;
    }
    .Invite-frm{
        border: 1px solid #eee;
        border-radius: 8px;
        text-align: center;
        font-weight:500;
        color:rgba(204,153,90,1);
    }
    .reg-frm>div {
        width: 240px;
        margin: 50px auto;
    }
</style>